import { NextPage, NextPageContext } from 'next'

import * as Sentry from '@sentry/nextjs'
import NextErrorComponent from 'next/error'

type CustomErrorComponentsProps = {
  statusCode: number
}

const CustomErrorComponent: NextPage<CustomErrorComponentsProps> = (props) => {
  return <NextErrorComponent statusCode={props.statusCode} />
}

CustomErrorComponent.getInitialProps = async (contextData: NextPageContext) => {
  await Sentry.captureUnderscoreErrorException(contextData)

  return NextErrorComponent.getInitialProps(contextData)
}

export default CustomErrorComponent
